import { useEffect, useState } from "react";
import {
  CustomButton,
  CustomTextField,
  CustomNumberField,
  NavigateBack,
  CustomSelectField,
  Loader,
  Tooltip,
} from "../../../components";
import { Heading } from "../AppSettingsStyles";
import { Notify, Utils } from "../../../utils";
import { Constant } from "../../../config";
import { ServiceCodeService } from "../../../_service";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { ReportSectionHeading } from "../../reports/ReportFilterPageStyles";
import { useAppDispatch, useAppSelector } from "../../../_app";
import { LoaderContainer } from "../../../CommonStyles";
import { IRASTable } from "../../filing/CPFFilingStyles";
import { TableHeader } from "../../../components/table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material";
import { updateAppSettingId } from "../../../_features";
import { AiFillDelete } from "react-icons/ai";
import NumberField from "../../../components/customFormField/NumberField";
import { log } from "console";
import TextField from "../../../components/customFormField/TextField";
interface serviceCodesInterface {
  id: number;
  name: string;
  fixed_amount: number;
  variable_amount: number;
  description: string;
}

const GenerateInvoice = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const date = new Date();
  let isViewOnly = location?.state?.isViewOnly; 
  let isEdit = location?.state?.isEdit;

  console.log("isViewOnly", isViewOnly, "isEdit", isEdit, "location", location);


  const companyId = useAppSelector((state) => state.user.defaultCompnayId);
  const currentPayrunDate = useAppSelector(
    (state) => state.user.current_payrun_date
  );

  const [serviceCodes, setServiceCodes] = useState<serviceCodesInterface[]>([])
  const [serviceCodesList, setServiceCodesList] = useState<serviceCodesInterface[]>([])
  const [selectedServiceCode, setSelectedServiceCode] = useState<serviceCodesInterface | null>(null)


  const [invoiceNumber, setInvoiceNumber] = useState<string>("");
  const [invoiceData, setInvoiceData] = useState<any>({});
  const [scInvoiceData, setScInvoiceData] = useState<any>({});


  const [noOfEmployee, setnoOfEmployee] = useState(0)
  let [totalAmount, settotalAmount] = useState(0)
  const [grandTotalUpdated, setGrandTotalUpdated] = useState<number>(scInvoiceData?.total_amount?.map((x: any) => x += x));


  const [isLoading, setIsLoading] = useState<boolean>(true);
  const monthsOptions = Utils.MONTH_OPTIONS;
  const yearOptions = Utils.getDefaultYearRange(parseInt(currentPayrunDate?.split('-')[0]));

  const handleAddService = (servicecode: any) => {
    setnoOfEmployee(0)
    settotalAmount(0)
    if (totalAmount === 0) {
      totalAmount = totalAmount + servicecode.fixed_amount
    }
    const updatedFormData = {
      service_id: servicecode.id,
      name: servicecode.name,
      description: servicecode.description,
      fixed_amount: servicecode.fixed_amount,
      variable_amount: servicecode.variable_amount,
      no_of_employees: noOfEmployee,
      total_amount: totalAmount,
    };

    const isDuplicate = scInvoiceData.some((sc: any) => sc.service_id === servicecode.id);

    if (isDuplicate) {
      Notify("This service is already added!", 0);

    } else {
      setScInvoiceData((prev: any) => [...prev, updatedFormData]);
      Notify("Service added Successfully", 1);

    }
  };

  console.log("scInvoiceData", scInvoiceData);


  // const [month, setMonth] = useState<string>(`${1}`);
  // const [year, setYear] = useState<string>(`${moment().year()}`);

  const [year, setYear] = useState<string>(isViewOnly || isEdit ? location?.state?.date?.split('-')[0] :
    moment(currentPayrunDate)?.subtract(1, 'months').format("YYYY-MM-DD")?.split("-")[0] || date.getFullYear()
  );
  const [month, setMonth] = useState<string>(isViewOnly || isEdit ? location?.state?.date?.split('-')[1] :
    `${moment(currentPayrunDate)?.subtract(1, 'months').format("YYYY-MM-DD")?.split("-")[1] || date.getMonth()}`
  );


  console.log("month", month, location?.state?.date?.split('-')[1], moment(currentPayrunDate)?.subtract(1, 'months').format("YYYY-MM-DD")?.split("-")[1]);

  // console.log("currentPayrunDate", currentPayrunDate, moment(currentPayrunDate)?.subtract(1, 'months').format("YYYY-MM-DD"))

  //   const addServiceCode = async () => {
  //     try {
  //       const { status } = await ServiceCodeService._addServiceCode({
  //         name: servicecode,
  //         fixed_amount: fixedAmount,
  //         variable_amount: variableAmount,
  //       });
  //       if (status === 201) {
  //         Notify("Service code added successfully", 1);
  //         navigate("/dashboard/manage-service-code");
  //       }
  //     } catch (error: any) {
  //       if (error?.response?.data?.name?.message) {
  //         Notify(error.response.data.name.message, 0);
  //       } else {
  //         Notify(Constant.common.something_went_wrong, 0);
  //       }
  //     }
  //   };

  //   const editServiceCode = async () => {
  //     try {
  //       const { status } = await ServiceCodeService._editServiceCode(
  //         {
  //           name: servicecode,
  //           fixed_amount: fixedAmount,
  //           variable_amount: variableAmount,
  //         },
  //         location.state.id
  //       );
  //       if (status === 200) {
  //         Notify("Service code edited successfully", 1);
  //         navigate("/dashboard/manage-service-code");
  //       }
  //     } catch (error: any) {
  //       if (error?.response?.status === 400) {
  //         if (error?.response?.data?.name?.message) {
  //           Notify(error.response.data.name.message, 0);
  //         } else {
  //           Notify(Constant.common.something_went_wrong, 0);
  //         }
  //       } else {
  //         Notify(Constant.common.something_went_wrong, 0);
  //       }
  //     }
  //   };

  const serviceCodeTableHeaders = [
    {
      name: "Service code",
      width: "17%",
    },
    {
      name: "Discription",
      width: "21%",
    },
    {
      name: "Fixed amount",
      width: "17%",
    },
    {
      name: "Variable Amount",
      width: "17%",
    },
    {
      name: "No. of Employees",
      width: "17%",
    },
    {
      name: "Total",
      width: "17%",
    },
    {
      name: "Delete",
      width: "",
    },
  ];

  const getInvoiceData = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await ServiceCodeService._getInvoiceData(
        companyId,
        `01-${month}-${year}`
      );
      // console.log("123", data.data.company_data.service_provider, companyId);


      if (status >= 200 && status < 300) {
        setInvoiceData(data?.data);
        setScInvoiceData(data?.data.invoice_data.items)
      }
    } catch (e: any) {
      if (e?.response?.data?.message) {
        Notify(e?.response?.data?.message, 0);
      }
      else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
    setIsLoading(() => false);
  };

  const getInvoiceViewData = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await ServiceCodeService._viewInvoice(
        location?.state?.id
      );

      if (status >= 200 && status < 300) {
        console.log("view res", data?.data);
        setInvoiceData(data?.data);
        setScInvoiceData(data?.data.invoice_data.json_data.items)

      }
    } catch (e: any) {
      if (e?.response?.data?.message) {
        Notify(e?.response?.data?.message, 0);
      }
      else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
    setIsLoading(() => false);
  };



  const handleSave = async (action: any) => {
    // if (!invoiceNumber) {
    //   Notify("Please enter the invoice number", 0);
    //   return null;
    // }



    if (!isEdit) {

      let payload = {
        company: companyId,
        date: `${year}-${month}-01`,
        json_data: {
          grand_total: grandTotalUpdated,
          items: scInvoiceData
        },
      };

      try {
        const { status } = await ServiceCodeService._saveInvoice(action, payload);

        if (status >= 200 && status < 300) {
          Notify("Invoice save and send Successfully", 1);
          navigate(-1);
        }
      } catch (e: any) {
        if (e?.response?.data?.message || e?.response?.data?.number[0]) {
          console.log("error", e?.response?.data?.message);
          Notify(e?.response?.data?.message || e?.response?.data?.number[0], 0);
        } else {
          Notify(Constant.common.something_went_wrong, 0);
        }
      }

    }
    else {
      let payload = {
        json_data: {
          grand_total: grandTotalUpdated,
          items: scInvoiceData
        },
      }

      try {
        const { status } = await ServiceCodeService._EditInvoice(location?.state?.id, action, payload);
        if (status >= 200 && status < 300) {
          if (status >= 200 && status < 300) {
            Notify(Constant.invoice.send, 1);
          }
          navigate(-1);
        }
      } catch (e: any) {
        if (e?.response?.data?.message || e?.response?.data?.number[0]) {
          console.log("error", e?.response?.data?.message);
          Notify(e?.response?.data?.message || e?.response?.data?.number[0], 0);
        } else {
          Notify(Constant.common.something_went_wrong, 0);
        }
      }
    }
  };


  // Resend and Download
  const handleResend = async (invoiceId: any) => {
    setIsLoading(() => true);
    try {
      const { status } = await ServiceCodeService._resendInvoice(invoiceId);

      if (status >= 200 && status < 300) {
        Notify("Resent Successfully", 1);
      }
    } catch (error: any) {
      if (error?.response?.status === 400) {
        Notify(error.response.data?.message, 0);
      } else {
        Notify(Constant.common.something_went_wrong, 0);
      }
    }
    setIsLoading(() => false);
  };

  const handlePDFDownload = async (invoiceId: any) => {
    try {
      let response = await ServiceCodeService?._downloadInvoice(invoiceId);
      if (response.status >= 200 && response.status < 300) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `invoice.pdf`; // Set the desired filename for the downloaded file
        document.body.appendChild(a);
        a.click();
      }
    } catch (error) {
      // Notify(Constant.common.something_went_wrong, 0);
      Notify("No PDF Available", 0);
    }
  };
  useEffect(() => {
    getServiceCodesList()
  }, [])
  useEffect(() => {
    if (isEdit) {
      setInvoiceData((prev: any) => {
        return { ...prev, invoice_data: {} }
      });
      getInvoiceViewData();
    } else {
      setInvoiceData((prev: any) => {
        return { ...prev, invoice_data: {} }
      });
      getInvoiceData();
    }
  }, [month, year, isEdit]);

  useEffect(() => {
    if (Array.isArray(scInvoiceData)) {
      const total = scInvoiceData.reduce(
        (acc, curr) => acc + (curr.total_amount || 0),
        0
      );
      setGrandTotalUpdated(total);
    } else {
      console.error("scInvoiceData is not an array:", scInvoiceData);
      setGrandTotalUpdated(0); // Default fallback
    }
  }, [scInvoiceData]);

  let dataToMap = isViewOnly ? invoiceData?.invoice_data?.json_data?.items : invoiceData?.invoice_data?.items;
  let grandTotal = isViewOnly ? scInvoiceData?.invoice_data?.json_data?.grand_total : scInvoiceData?.invoice_data?.grand_total;


  if (invoiceData?.is_service_subscribed === false) {
    return <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "5rem",
        flexDirection: "column",
        gap: "0.75rem",
        padding: '0.5rem',
      }}
    >
      <p
        style={{
          color: "rgb(33, 37, 41)",
          fontWeight: 600,
          fontSize: "1.25rem",
        }}
      >{`There is no service code attached to this company or there is no service enabled.`}</p>
      <p
        style={{
          color: "rgb(33, 37, 41)",
          fontWeight: 600,
          fontSize: "1.25rem",
          marginBottom: '1rem'
        }}
      >{`Please click the button below to go to the Employer Details page and add/enable a service.`}</p>

      <CustomButton
        title="Edit Employer Details"
        onClick={() => {
          dispatch(updateAppSettingId(companyId));
          navigate("/dashboard/app-settings/employer-details")
        }}
      />
    </div>
  }
  if (invoiceData?.is_service_provider_selected === false) {
    return <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "5rem",
        flexDirection: "column",
        gap: "0.75rem",
        padding: '0.5rem',
      }}
    >
      <p
        style={{
          color: "rgb(33, 37, 41)",
          fontWeight: 600,
          fontSize: "1.25rem",
        }}
      >{`There is no Service Provider Company (SPC) attached to this company.`}</p>
      <p
        style={{
          color: "rgb(33, 37, 41)",
          fontWeight: 600,
          fontSize: "1.25rem",
          marginBottom: '1rem'
        }}
      >{`Please click the button below to go to the Employer Details page and select service provider company.`}</p>

      <CustomButton
        title="Edit Employer Details"
        onClick={() => {
          dispatch(updateAppSettingId(companyId));
          navigate("/dashboard/app-settings/employer-details")
        }}
      />
    </div>

    const getServiceCodesList = async () => {
      try {
        const { data, status } = await ServiceCodeService._getServiceCodeList();
        if (status === 200) {
          setServiceCodesList(() =>
            data.data.map((dataObj: any) => ({
              id: dataObj.id,
              name: dataObj.name,
              fixed_amount: dataObj.fixed_amount,
              variable_amount: dataObj.variable_amount,
              description: dataObj.description,
            }))
          );
        }
      } catch (error) {
        Notify(Constant.common.something_went_wrong, 0);
      }

    };
  }
  const getServiceCodesList = async () => {
    try {
      const { data, status } = await ServiceCodeService._getServiceCodeList();
      if (status === 200) {
        setServiceCodesList(() =>
          data.data.map((dataObj: any) => ({
            id: dataObj.id,
            name: dataObj.name,
            fixed_amount: dataObj.fixed_amount,
            variable_amount: dataObj.variable_amount,
            description: dataObj.description,
          }))
        );
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }

  };


  const handleChange = (e: any, id: any, field: any) => {

    const newData = scInvoiceData.map((row: any) => {
      if (row.service_id === id) {
        const updatedValue = field === "description" ? e?.target?.value : parseFloat(e?.target?.value) || 0;

        const updatedRow = { ...row, [field]: updatedValue };

        // Recalculate total_amount only if numeric fields are updated
        if (field === "fixed_amount" || field === "variable_amount" || field === "no_of_employees") {
          updatedRow.total_amount =
            (parseFloat(updatedRow.fixed_amount) || 0) +
            (parseFloat(updatedRow.variable_amount) || 0) *
            (parseFloat(updatedRow.no_of_employees) || 0);
        }

        return updatedRow;
      }
      return row;
    });

    setScInvoiceData(newData); // Update the state with new data
  };

  const onDeleteButton = (sid: any) => {
    console.log("Deleting item with service_id:", sid);

    const updatedData = scInvoiceData.filter((item: any) => item.service_id !== sid);

    setScInvoiceData(updatedData); // Assuming setScInvoiceData is your state update function
  };


  console.log("paris", scInvoiceData, grandTotalUpdated);


  // const handleAddService = async (serviceId: any) => {
  //   if (!serviceId) {
  //     Notify("No service selected", 0);
  //     return;
  //   }

  //   if (selectedServiceCode) {
  //     setServiceCodes((prevServiceCodes) => [
  //       ...prevServiceCodes,
  //       { ...selectedServiceCode, is_active: true },
  //     ]);
  //     setSelectedServiceCode(null);
  //     await getInvoiceData();
  //     Notify("Added service code Successfully", 1);
  //   }
  // };


  console.log("!location?.state?.isViewOnly &&", !location?.state?.isViewOnly);
  console.log("scInvoiceData", scInvoiceData);


  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <div style={{ fontSize: '.9rem' }}>


      <div style={{ padding: "1rem" }}>
        {/* Header Section */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            marginBottom: ".2rem",
          }}
        >
          <NavigateBack />
          <Heading>
            {isViewOnly || isEdit
              ? `Invoice - ${moment(location?.state?.formatted_date, "DD-MM-YYYY").format("D MMMM YYYY")}`
              : "Add New Single Invoice"}
          </Heading>
        </div>

        {/* SPC Company */}
        <div style={{ display: "flex", gap: "1.5rem", marginBottom: ".2rem" }}>
          <span
            style={{
              color: "#476575",
              fontWeight: 600,
              fontSize: "1rem",
              whiteSpace: "nowrap",
            }}
          >
            SPC Company:
          </span>
          <span style={{ color: "#333" }}>
            {invoiceData?.company_data?.service_provider || "N/A"}
          </span>
        </div>

        {/* Invoice Date Section */}
        {!isEdit && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "2.2rem",
              marginBottom: ".3rem",
            }}
          >
            <p
              style={{
                color: "#476575",
                fontWeight: 600,
                fontSize: "1rem",
              }}
            >
              Invoice Date:
            </p>
            <div style={{ display: "flex", gap: "1.5rem" }}>
              <CustomSelectField
                value={month}
                onChangeOption={setMonth}
                option={monthsOptions.map((month) => ({
                  key: month.key,
                  value: month.value,
                }))}
                disabled={isLoading}
                isDate
              />
              <CustomSelectField
                value={year}
                onChangeOption={setYear}
                option={yearOptions.map((year) => ({
                  key: year,
                  value: year,
                }))}
                disabled={isLoading}
                isDate
              />
            </div>
          </div>
        )}

        {/* Invoice Number Section */}
        {!isEdit && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1.5rem",
              marginBottom: ".2rem",
            }}
          >
            <span
              style={{
                color: "#476575",
                fontWeight: 600,
                fontSize: "1rem",
              }}
            >
              Invoice Number:
            </span>
            {/* <CustomNumberField
              value={invoiceNumber}
              onChangeText={setInvoiceNumber}
              width={"fit-content"}
              placehoder={"000#"}
            /> */}
            <div>{invoiceData.invoice_data.number}</div>
          </div>
        )}

        {/* Customer Information */}
        <div style={{
          display: 'flex',
          justifyContent: "space-between",
        }}>
          <div
            style={{
              marginTop: ".5rem",
              borderTop: "1px solid #e0e0e0",
              paddingTop: ".5rem",
            }}
          >
            <h3 style={{
              paddingBottom: ".5rem"
            }}>Customer Information</h3>

            <div style={{
              display: 'flex',
              justifyContent: "space-between"
            }}>
              {/* Company Information */}
              <div style={{ display: "flex", gap: "4rem", }}>
                <p style={{ color: "#495057", width: "5rem", fontWeight: 600 }}>Company:</p>
                <p style={{ color: "#333" }}>{invoiceData?.company_data?.company_name || "N/A"}</p>
              </div>



            </div>

            {/* Address Section */}
            <div style={{ display: "flex", gap: "4rem", marginBottom: '.2rem' }}>
              <p style={{ color: "#495057", width: "5rem", fontWeight: 600 }}>Address:</p>
              <div style={{ color: "#333" }}>
                <p>{invoiceData?.company_data?.address_l1 || ""}</p>
                <p>{invoiceData?.company_data?.address_l2 || ""}</p>
                <p>{invoiceData?.company_data?.address_l3 || ""}{"  "}{invoiceData?.company_data?.address_code || ""}</p>
              </div>
            </div>



            {/* Authorised Person */}
            <div style={{ display: "flex", gap: "1rem", marginBottom: '.2rem' }}>
              <p style={{ color: "#495057", width: "8rem", fontWeight: 600 }}>Contact Name:</p>
              <p style={{ color: "#333" }}>{invoiceData?.company_data?.authorised_person_name || "N/A"}</p>
            </div>

            <div style={{ display: "flex", gap: "1rem", marginBottom: '.2rem' }}>
              <p style={{ color: "#495057", width: "8rem", fontWeight: 600 }}>Email Address:</p>
              <p style={{ color: "#333" }}>{invoiceData?.company_data?.authorised_person_email || "N/A"}</p>
            </div>
            <div style={{ display: "flex", gap: "1rem", marginBottom: '.2rem' }}>
              <p style={{ color: "#495057", width: "8rem", fontWeight: 600 }}>Telephone No:</p>
              <p style={{ color: "#333" }}>{invoiceData?.company_data?.authorised_person_tel_no
                || "N/A"}</p>
            </div>
          </div>



          <div style={{
                display:"flex",
                flexDirection : "column",
                justifyContent : "space-between",
                alignItems : "end",
                paddingTop : "3rem",
          }}>
            <div style={{
              display: "flex",
              flexDirection: "column",
              gap: '0.6rem',
              alignContent: 'no-wrap',
              width : "100%"


            }}>
              <div style={{
                fontWeight: 500,
                color: "#333",
                width : "40%",
                whiteSpace : "nowrap",
              }}>
                {isEdit  
                  ? `Invoice Date: ${moment(invoiceData?.invoice_data?.date).format("D MMMM YYYY")}`
                  : ""}
              </div>
              {/* Invoice Number (View-Only Mode) */}

              {isEdit && (
                <div
                  style={{
                    display: "flex",
                    gap: ".4rem",
                    fontWeight: 500,
                    color: "#333",
                    justifyItems: 'start',
                    width : "60%",
                    whiteSpace : "nowrap"

                  }}
                >
                  <p style={{ width: "9rem" }}>Invoice Number:</p>
                  <p>{location?.state?.number}</p>
                </div>
              )}


            </div>
            {(!location?.state?.isViewOnly || invoiceData.invoice_data.status == "saved") && <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label style={{ marginBottom: "3px", fontSize: "15px", whiteSpace: "nowrap" }}>
                  Service Code
                </label>
                <select
                  value={selectedServiceCode?.id || ""}
                  onChange={(e) => {
                    const selectedId = Number(e.target.value);
                    const selectedService =
                      serviceCodesList.find((service: any) => service.id === selectedId) || null;
                    setSelectedServiceCode(selectedService);
                  }}
                  style={{
                    padding: "5px",
                    borderRadius: "0.2rem",
                    border: "1px solid #ccc",
                    minWidth: "180px"

                  }}
                >
                  <option value="" disabled>
                    Select service code
                  </option>
                  {serviceCodesList.map((option: any) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>


              <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                <label style={{ marginBottom: "3px", fontSize: "12px", color: "#F9F9F9" }}>
                  Action
                </label>
                <CustomButton
                  title="Add"
                  onClick={() => handleAddService(selectedServiceCode)}
                  size="small"
                  width="80px"
                  disable={selectedServiceCode === null ? true : false}

                />
              </div>
            </div>
            }

            

          </div>
        </div>
      </div>

      {!isViewOnly && <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                background: "#b8daff",
              }}
            >
              <TableCell sx={{ fontWeight: 600 }} align="left">
                Code
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="left">
                Description
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="right">
                Fixed Amount
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="right">
                Per Employees
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="right">
                {" "}
                Number
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="right">
                {" "}
                Amount
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="center">
                {" "}
                Delete
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {scInvoiceData?.length > 0 && scInvoiceData?.map((item: any) => (
              <TableRow
                key={item.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  // background: "#e9f3ff",
                }}
              >
                <TableCell align="left">{item.name}</TableCell>
                <TableCell align="left">
                  {/* <input
                    type="text"
                    value={item.description}
                    onChange={(e) => handleFieldChange(item.service_id, "description", e.target.value)}
                    style={{ textAlign: "left", padding: "5px", borderRadius: "5px", width: "450px" }}
                  /> */}
                  <input
                    value={item.description}
                    onChange={(e) =>
                      handleChange(e, item.service_id, "description")
                    }
                    style={{ textAlign: "left", padding: "5px 5px", borderRadius: "4px", width: "450px" }}

                  />
                </TableCell>
                <TableCell align="right">
                  <input
                    value={item.fixed_amount}
                    onChange={(e) =>
                      handleChange(e, item.service_id, "fixed_amount")
                    }
                    style={{ textAlign: "right", padding: "10px 2px", borderRadius: "4px" }}
                  />
                </TableCell>
                <TableCell align="right">
                  <input
                    value={item.variable_amount}
                    onChange={(e) =>
                      handleChange(e, item.service_id, "variable_amount")
                    }
                    style={{ textAlign: "right", padding: "10px 2px", borderRadius: "4px" }}
                  />
                </TableCell>
                <TableCell align="right">
                  <input
                    value={item.no_of_employees}
                    onChange={(e) =>
                      handleChange(e, item.service_id, "no_of_employees")
                    }
                    style={{ textAlign: "right", padding: "10px 2px", borderRadius: "4px" }}
                  />
                </TableCell>
                <TableCell align="right">{`${Utils.getCommanSepartedNumericStringWithDollar(
                  item?.total_amount || "0"
                )}`}</TableCell>
                <TableCell align="right" style={{
                  paddingLeft: "3rem"
                }}>
                  <Tooltip text="Remove" del>
                    <CustomButton
                      type={"secondary"}
                      size="small"
                      del
                      width="30px"
                      title={""}
                      onClick={() => {
                        onDeleteButton(item.service_id)
                      }}
                      icon={<AiFillDelete />}
                    />
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
            {grandTotalUpdated && (
              <TableRow
                sx={{
                  background: "#b8daff",
                }}
              >
                <TableCell
                  sx={{ fontWeight: 600, fontSize: "1rem" }}
                  colSpan={6}
                  align="right"
                >{`Total Amount:`}</TableCell>
                <TableCell
                  sx={{ fontWeight: 600, fontSize: "1rem" }}
                  colSpan={0}
                  align="right"
                >
                  {`${Utils.getCommanSepartedNumericStringWithDollar(
                    grandTotalUpdated || ""
                  )}`}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      }



      {location?.state?.isViewOnly && <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow
              sx={{
                background: "#b8daff",
              }}
            >
              <TableCell sx={{ fontWeight: 600 }} align="left">
                Code
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="left">
                Description
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="right">
                Fixed Amount
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="right">
                Per Employees
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="right">
                {" "}
                Number
              </TableCell>
              <TableCell sx={{ fontWeight: 600 }} align="right">
                {" "}
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataToMap?.length > 0 && dataToMap?.map((item: any) => (
              <TableRow
                key={item.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  // background: "#e9f3ff",
                }}
              >
                <TableCell component="th" scope="item" align="left">
                  {item.name}
                </TableCell>
                <TableCell component="th" scope="item" align="left">
                  {item.description || ""}
                </TableCell>
                <TableCell align="right">{`${Utils.getCommanSepartedNumericStringWithDollar(
                  item?.fixed_amount || ""
                )}`}</TableCell>
                <TableCell align="right">{`${Utils.getCommanSepartedNumericStringWithDollar(
                  item?.variable_amount || ""
                )}`}</TableCell>
                <TableCell align="right">{`${(
                  item?.no_of_employees || ""
                )}`}</TableCell>
                <TableCell align="right">{`${Utils.getCommanSepartedNumericStringWithDollar(
                  item?.total_amount || ""
                )}`}</TableCell>
              </TableRow>
            ))}
            {grandTotalUpdated && (
              <TableRow
                sx={{
                  background: "#b8daff",
                }}
              >
                <TableCell
                  sx={{ fontWeight: 600, fontSize: "1rem" }}
                  colSpan={5}
                  align="right"
                >{`Total Amount:`}</TableCell>
                <TableCell
                  sx={{ fontWeight: 600, fontSize: "1rem" }}
                  colSpan={1}
                  align="right"
                >{`${Utils.getCommanSepartedNumericStringWithDollar(
                  grandTotalUpdated || ""
                )}`}</TableCell>
              </TableRow>
            )}
            {/* {grandTotal && (
              <TableRow
                sx={{
                }}
              >
                <TableCell
                  sx={{ fontWeight: 600, fontSize: "1rem" }}
                  colSpan={5}
                  align="right"
                >{`Add GST 9%:`}</TableCell>
                <TableCell
                  sx={{ fontWeight: 600, fontSize: "1rem" }}
                  colSpan={1}
                  align="right"
                >
                 {`${Utils.getCommanSepartedNumericStringWithDollar(
                  grandTotal *9 /100
                )}`}
               </TableCell>
              </TableRow>
            )} */}
            {/* {grandTotal && (
              <TableRow
                sx={{
                  background: "#b8daff",
                }}
              >
                <TableCell
                  sx={{ fontWeight: 600, fontSize: "1rem"  }}
                  colSpan={5}
                  align="right"
                >{`Total:`}</TableCell>
                <TableCell
                  sx={{ fontWeight: 600, fontSize: "1rem", borderTop : "2px solid black"  }}
                  colSpan={1}
                  align="right"
                >{`${Utils.getCommanSepartedNumericStringWithDollar(
                  grandTotal || ""
                )}`}</TableCell>
              </TableRow>
            )} */}
          </TableBody>
        </Table>
      </TableContainer>
      }


      {/* Buttons */}



      {!isViewOnly  && <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          marginTop: "1.5rem",
          marginLeft: "auto",
          width: "100%",
        }}
      >
        <CustomButton title="Back" onClick={() => navigate(-1)} loading={isLoading} disable={isLoading} />
        <CustomButton title="Save" onClick={() => handleSave("save")} loading={isLoading} disable={isLoading} />
        <CustomButton
          title="Save & Send"
          onClick={() => handleSave("save-send")}
          loading={isLoading}
          disable={isLoading}
        />
      </div>}

      {dataToMap?.length > 0 && location?.state?.isViewOnly && <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "right",
          marginTop: "1.5rem",
          marginLeft: "auto",
          width: "100%",
        }}
      >
        <CustomButton title="Download PDF" onClick={() => handlePDFDownload(location?.state?.id)} loading={isLoading} disable={isLoading} />
        {invoiceData.invoice_data.status == "saved" && <CustomButton
          title="Resend"
          onClick={() => handleResend(location?.state?.id)}
          loading={isLoading}
          disable={isLoading}
        />}
      </div>}
    </div>
  );
};

export default GenerateInvoice;
