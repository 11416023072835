import { useNavigate } from "react-router-dom";
import "./manageEmployee.css";
import { useEffect, useState } from "react";
import { EmployeeService } from "../../../_service";
import { EmployeeListProps } from "../../../_types";
import EmployeeHeader from "./employeeHeader";
import { useAppDispatch, useAppSelector } from "../../../_app";
import {
  updateCurrentEmployee,
  updateCurrentPayslipDate,
  resetEmployee,
} from "../../../_features/employee";
import {
  CommonModal,
  CompanyInfoModal,
  CustomButton,
  CustomSelectField,
  Loader,
} from "../../../components";
import {
  FilterByChar,
  FilterBySelect,
  SearchField,
  TableFilterHeader,
} from "../component";
import styled from "styled-components";
import { Notify, Utils } from "../../../utils";
import { Constant } from "../../../config";
import { data } from "jquery";

const EmployeePayrollList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const date = new Date();

  const user = useAppSelector((state) => state.user);
  const [text, setText] = useState("");
  const [char, setChar] = useState("");
  const [status, setStatus] = useState("1");
  const [employeeList, setEmployeeList] = useState<EmployeeListProps[]>([]);
  const [originalEmployeeList, setOriginalEmployeeList] = useState<EmployeeListProps[]>([]);
  const [paryunStatus, setPayrunStatus] = useState<string>("");
  const [filterEmployeeList, setfilterEmployeeList] = useState<
    any[]
  >([]);
  const [sending, setSending] = useState(true);
  const [pageInfo, setPageInfo] = useState<{
    pageNumber: number;
    totalLength: number;
    pagePerData: number;
  }>({
    pageNumber: 1,
    totalLength: 10,
    pagePerData: 10,
  });
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false);
  const companyId = useAppSelector((state) => state.user.defaultCompnayId);
  const currentPayrunDate = useAppSelector(
    (state) => state.user.current_payrun_date
  );

  const [isFiltered, setIsFiltered] = useState(false);
  const [month, setMonth] = useState<string>(
    `${parseInt(currentPayrunDate?.split("-")[1] || 0) || date.getMonth() + 1}`
  );
  const [employeeYear, setEmployeeYear] = useState(
    currentPayrunDate?.split("-")[0] || date.getFullYear()
  );

  const [totalValues, setTotalValues] = useState<any>({
    totalSalary: 0,
    totalDeduction: 0,
    totalBankToPay: 0,
    totalCPFB: 0,
    totalEmployees: 0
  })

  // const yearsTillNow = [
  //   ...Utils.generateYearArrayTillNow(2022),
  //   date.getFullYear() + 1,
  // ];

  const yearsTillNow = Utils.getDefaultYearRange(currentPayrunDate?.split("-")[0] || date.getFullYear());
  const monthsOptions = Utils.MONTH_OPTIONS;

  const textColor = {
    new: "#155724",
    existing: "#059BBF",
    resigned: "#dc3545",
  } as any;

  const getEmployeeList = async (type: string) => {
    console.log("getEmp called");
    const defaultCompanyId = user.defaultCompnayId;
    if (type !== "0") {
      setIsFiltered(() => true);
    } else {
      setIsFiltered(() => false);
    }
    if (defaultCompanyId) {
      try {
        setSending(() => true);
        const res = await EmployeeService._getEmployeePayrollListByCompany(
          defaultCompanyId,
          Utils.getLastDayOfMonth(employeeYear, month)
        );
        if (res.status === 200) {
          setOriginalEmployeeList(() => res?.data?.data?.emp_payslip);
          setEmployeeList(() => res?.data?.data?.emp_payslip);
          setfilterEmployeeList(() => res?.data?.data?.emp_payslip);
          setPayrunStatus(() => res?.data?.data?.payrun_status);
          setTotalValues(() => ({
            totalSalary: res?.data?.data?.summary?.gross || 0,
            totalDeduction: res?.data?.data?.summary?.total_deduction || 0,
            totalBankToPay: res?.data?.data?.summary?.net_pay || 0,
            totalCPFB: res?.data?.data?.summary?.cpfb || 0,
            totalEmployees: res?.data?.data?.summary?.total_employee || 0
          }));
          setPageInfo((prev) => ({
            ...prev,
            totalLength: res?.data?.data?.emp_payslip?.length,
          }));
        }
        setChar("All");
        setSending(() => false);
      } catch (e: any) {
        console.log(e);
        setSending(() => false);
        setEmployeeList(() => []);
        setfilterEmployeeList(() => []);
        setTotalValues(() => ({
          totalSalary: 0,
          totalDeduction: 0,
          totalBankToPay: 0,
          totalCPFB: 0,
          totalEmployees: 0
        }));
        setPageInfo((prev) => ({
          ...prev,
          totalLength: 0,
        }));
        if (e.response?.data?.payslip_date?.length > 0) {
          Notify(e.response?.data?.payslip_date[0], 0);
          // disaptch(resetUser());
        }
      }
    }
  };

  const getEmployeeListByChar = async (val: string) => {
    setIsFiltered(true);
    try {
      setSending(true);
      const defaultCompanyId = user.defaultCompnayId;
      const data = await EmployeeService._getEmployeePayrollListByChar(
        defaultCompanyId,
        val,
        Utils.getLastDayOfMonth(employeeYear, month)
      );
      setEmployeeList(() => data.data?.data?.emp_payslip);
      setfilterEmployeeList(() => data.data?.data?.emp_payslip);
      setPayrunStatus(() => data?.data?.data?.payrun_status);
      setTotalValues(() => ({
        totalSalary: data?.data?.data?.summary?.gross || 0,
        totalDeduction: data?.data?.data?.summary?.total_deduction || 0,
        totalBankToPay: data?.data?.data?.summary?.net_pay || 0,
        totalCPFB: data?.data?.data?.summary?.cpfb || 0,
        totalEmployees: data?.data?.data?.summary?.total_employee || 0,
      }));
      setPageInfo((prev) => ({
        ...prev,
        totalLength: data?.data?.data?.emp_payslip?.length || 0,
      }));

      setSending(() => false);
    } catch (e: any) {
      setSending(() => false);
      console.error(e);
    }
  };
  const handlePageValueChange = (
    type: "next" | "previous" | "first" | "last"
  ) => {
    if (type === "next") {
      const isLast =
        pageInfo.pageNumber * pageInfo.pagePerData < employeeList?.length;
      if (isLast) {
        setPageInfo((prev) => ({ ...prev, pageNumber: prev.pageNumber + 1 }));
      }
    }
    if (type === "previous") {
      if (pageInfo.pageNumber > 1) {
        setPageInfo((prev) => ({
          ...prev,
          pageNumber: prev.pageNumber - 1,
        }));
      }
    }
    if (type === "first") {
      setPageInfo((prev) => ({
        ...prev,
        pageNumber: 1,
      }));
    }
    if (type === "last") {
      setPageInfo((prev) => ({
        ...prev,
        pageNumber: Math.ceil(pageInfo.totalLength / pageInfo.pagePerData),
      }));
    }
  };
  const handleNavigate = (item: EmployeeListProps) => {
    dispatch(resetEmployee());
    dispatch(
      updateCurrentEmployee({
        currentEmployee: {
          first_name: item?.first_name,
          last_name: item?.last_name,
          id: item?.id,
          name: item?.user?.name,
          payslip_date: Utils.getLastDayOfMonth(employeeYear, month),
          showPayroll: true,
          eos: item?.eos || null,
        },
      })
    );
    const payslipDate = Utils.getLastDayOfMonth(employeeYear, month);
    dispatch(updateCurrentPayslipDate(payslipDate));
    navigate("/dashboard/payroll/emp", { state: { reload: true,  lastDay: item?.eos?.last_date_of_service || "", firstDay: item?.date_of_appointment} });
  };
  const handleDataUpdateByChar = async (props: string) => {
    setIsFiltered(true);
    setChar(props);
    if (props === "All") {
      getEmployeeList(status);
    } else {
      getEmployeeListByChar(props);
    }
  };

  const sendForApproval = async () => {
    const dataToApprove = {
      company: companyId,
      payslip_date: Utils.getLastDayOfMonth(employeeYear, month),
    };

    try {
      const { status } = await EmployeeService._sendForApproval(dataToApprove);

      if (status >= 200 && status < 300) {
        Notify("Payslips Sent for Approval", 1);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsApprovalModalOpen(() => false);
  };

  useEffect(() => {
    getEmployeeList(status);
  }, [month, employeeYear, status]);

  useEffect(() => {
    if (text === "") {
      setfilterEmployeeList(employeeList);
    } else {
      setfilterEmployeeList(() => {
        return employeeList.filter((item) => {
          if (item?.user?.name?.toLowerCase().includes(text?.toLowerCase())) {
            return true;
          }
          return false;
        });
      });
    }
  }, [text]);
  // page change
  useEffect(() => {
    const startIndex =
      pageInfo.pageNumber * pageInfo.pagePerData - pageInfo.pagePerData;
    const endIndex =
      pageInfo.pageNumber * pageInfo.pagePerData > employeeList?.length
        ? employeeList?.length
        : pageInfo.pageNumber * pageInfo.pagePerData;
    setfilterEmployeeList(() => employeeList?.slice(startIndex, endIndex));
  }, [pageInfo.pageNumber, employeeList]);


  const getCPFB = (item :any) => {
    
    let cpf_employee = Utils.getPriceFromString(item?.employee_payslip?.payslip_info?.deductions?.data?.filter((x:any) => x?.name === "CPF - Employee")[0]?.value || "0.00")
    let sdl = Utils.getPriceFromString(item?.employee_payslip?.payslip_info?.employer_contribution?.data?.filter((x:any) => x?.name === "SDL")[0]?.value || "0.00")
    let cpf_employer = Utils.getPriceFromString(item?.employee_payslip?.payslip_info?.employer_contribution?.data?.filter((x:any) => x?.name === "CPF - Employer")[0]?.value || "0.00")

    
    // SHG (Self Help Group: ECF, SINDA, MBMF, CDAC)
    let cdac = (item?.employee_payslip?.payslip_info?.deductions?.data?.filter((x:any) => x?.name === "CDAC")[0]?.value || "0.00");
    let sinda = (item?.employee_payslip?.payslip_info?.deductions?.data?.filter((x:any) => x?.name === "SINDA")[0]?.value || "0.00");
    let ecf = (item?.employee_payslip?.payslip_info?.deductions?.data?.filter((x:any) => x?.name === "ECF")[0]?.value || "0.00");
    let mbmf = (item?.employee_payslip?.payslip_info?.deductions?.data?.filter((x:any) => x?.name === "MBMF")[0]?.value || "0.00");
    
    let shg = parseFloat(cdac) + parseFloat(sinda) + parseFloat(ecf) + parseFloat (mbmf);

    // console.log("SHG", shg, item?.user?.name,  {cdac, sinda, ecf, mbmf})

    let cpfb =( (parseFloat(cpf_employee) || 0) + (parseFloat(cpf_employer) || 0) + (parseFloat(sdl) || 0) + (parseFloat(`${shg}`) || 0)).toFixed(2);
    
    return Utils.prependDollar(Utils.getNumberWithCommas(cpfb || 0));
  }

  const getTotalCPF = (item: any) => {
    let cpf_employee = Utils.getPriceFromString(item?.employee_payslip?.payslip_info?.deductions?.data?.filter((x:any) => x?.name === "CPF - Employee")[0]?.value || "0.00")
    let cpf_employer = Utils.getPriceFromString(item?.employee_payslip?.payslip_info?.employer_contribution?.data?.filter((x:any) => x?.name === "CPF - Employer")[0]?.value || "0.00")

    let totalCPFB =( (parseFloat(cpf_employee) || 0) + (parseFloat(cpf_employer) || 0)).toFixed(2);

    return Utils.prependDollar(Utils.getNumberWithCommas(totalCPFB || 0));
  }

  // const getTotalSums = () => {
  //   console.log('getTotalSums called', originalEmployeeList)
  //   let totalObj = {
  //     totalSalary: 0,
  //     totalDeduction: 0,
  //     totalBankToPay: 0,
  //     totalCPFB: 0,
  //   };

  //   originalEmployeeList
  //     ?.forEach((item: any, index: number) => {
  //       totalObj.totalSalary =
  //         totalObj.totalSalary +
  //         parseFloat(
  //           Utils.getPriceFromString(
  //             item?.employee_payslip?.payslip_info?.income?.total || "0.00"
  //           )
  //         ) +
  //         parseFloat(
  //           Utils.getPriceFromString(
  //             item?.employee_payslip?.payslip_info?.allowance?.total || "0.00"
  //           )
  //         );

  //       totalObj.totalDeduction += parseFloat(
  //         Utils.getPriceFromString(
  //           item?.employee_payslip?.payslip_info?.deductions?.total || "0.00"
  //         )
  //       );

  //       totalObj.totalBankToPay += parseFloat(
  //         Utils.getPriceFromString(
  //           item?.employee_payslip?.payslip_info?.net_pay || "0.00"
  //         )
  //       );

  //       totalObj.totalCPFB += parseFloat(
  //         Utils.getPriceFromString(getCPFB(item)?.slice(1))
  //       );
  //     });

  //   console.log("totalValues", totalObj);
  //   setTotalValues(totalObj);
  // };

  console.log('filteredList', filterEmployeeList)

  // useEffect(()=>{
  //   getTotalSums();

  // }, [filterEmployeeList])


  return (
    <div style={{ position: "relative" }}>
      {!sending &&
      employeeList &&
      employeeList?.length < 1 &&
      (char === "" || char === "All") &&
      !isFiltered ? (
        <CompanyInfoModal />
      ) : null}
      <div className="hero_header">
        <h2 className="employee-header-h2">Manage Employees</h2>
      </div>
      <EmployeeHeader />
      <div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "1rem",
          }}
        >
          {/* <FilterBySelect value={status} onChageOption={changeStatus} /> */}
          <SearchField value={text} onChangeText={setText} />
          <div style={{ marginBottom: 15 }}>
            <CustomSelectField
              placeholder="Select Month"
              width="100%"
              margin="0rem"
              value={month}
              onChangeOption={(value: any) => setMonth(() => value)}
              option={monthsOptions.map((month: any) => ({
                key: month.key,
                value: month.value,
              }))}
            />
          </div>
          <div style={{ marginBottom: 15 }}>
            <CustomSelectField
              placeholder="Select Year"
              width="100%"
              margin="0rem"
              value={employeeYear}
              onChangeOption={(value: any) => setEmployeeYear(value)}
              option={yearsTillNow.map((years) => ({
                key: years,
                value: years,
              }))}
            />
          </div>
        
        </div>
        <FilterByChar value={char} onChange={handleDataUpdateByChar} />

        {/* ---------- total table ---------- */}

        <table
          style={{ width: "100%", marginTop: "1rem", marginBottom: "0.25rem" }}
          className="company-table"
        >
          <thead className="company-table-head" style={{ textAlign: "right" ,width:"100%"}}>
            <tr style={{width:"100%"}}>
              <th
                style={{
                  width: "25%",
                  textAlign: "left",
                  backgroundColor: "#FF4500",
                  
                }}
              >
                {`Total Employees`}
              </th>
              <th
                style={{
                  width: "5%",
                  textAlign: "center",
                  backgroundColor: "#FF4500",
                  
                }}
              >
                {``}
              </th>
              <th
                style={{
                  width: "10%",
                  textAlign: "right",
                  backgroundColor: "#FF4500",
                  
                }}
              >
                {`Gross Income`}
              </th>
              <th
                style={{
                  width: "10%",
                  textAlign: "right",
                  backgroundColor: "#FF4500",
                  
                }}
              >
                {`Deductions`}
              </th>
              <th
                style={{
                  width: "10%",
                  textAlign: "right",
                  backgroundColor: "#FF4500",
                  
                }}
              >
                {`Bank to Pay`}
              </th>
              <th
                style={{
                  width: "10%",
                  textAlign: "right",
                  backgroundColor: "#FF4500",
                  
                }}
              >
                {`To Pay CPFB`}
              </th>
              <th
                style={{
                  width: "10%",
                  textAlign: "center",
                  backgroundColor: "#FF4500",
                  
                }}
              >
                {``}
              </th>
              <th
                style={{
                  width: "10%",
                  textAlign: "center",
                  backgroundColor: "#FF4500",
                  
                }}
              >
                {``}
              </th>
              <th
                style={{
                  width: "10%",
                  textAlign: "center",
                  backgroundColor: "#FF4500",
                  
                }}
              >
                {``}
              </th>
            </tr>
          </thead>
          <tr>
            
            <td
              style={{
                textAlign: "center",
                fontSize: "1.15rem",
                fontWeight: "bold",
              }}
            >
              {totalValues?.totalEmployees || 0}
            </td>
            <td >{""}</td>
            <td
              style={{
                textAlign: "right",
                fontWeight: "bold",
                fontSize: "1.15rem",
                color: "#03B381",
           
              }}
            >
              { Utils.getCommanSepartedNumericStringWithDollar(
                  (totalValues?.totalSalary || 0)
                )}
            </td>
            <td
              style={{
                textAlign: "right",
                fontWeight: "bold",
                fontSize: "1.15rem",
                color: "#ff6665",
              
              }}
            >
              {Utils.getCommanSepartedNumericStringWithDollar(
                  (totalValues?.totalDeduction || 0)
                )}
            </td>
            <td
              style={{
                textAlign: "right",
                fontWeight: "bold",
                fontSize: "1.15rem",
                color: "#03B381",
                 
              }}
            >
              {Utils.getCommanSepartedNumericStringWithDollar(
                  (totalValues?.totalBankToPay || 0)
                )}
            </td>
            <td
              style={{
                textAlign: "right",
                fontWeight: "bold",
                fontSize: "1.15rem",
                color: "#ff6665",
              
              }}
            >
              {Utils.getCommanSepartedNumericStringWithDollar(
                  (totalValues?.totalCPFB || 0)
                )}
            </td>
            <td>{""}</td>
            <td>{""}</td>
            <td>{""}</td>
          </tr>
        </table>

        {/* ----- main table --------- */}
        <div className="table">
          {sending && <Loader />}
          {filterEmployeeList?.length > 0 && (
            <TableFilterHeader
              name="Employee"
              pageInfo={pageInfo}
              setPageInfo={setPageInfo}
              handlePageValueChange={(val) => handlePageValueChange(val)}
            />
          )}
          <table
            style={{
              width: "100%",
              marginTop: "1rem",
              marginBottom: "0.25rem",
            }}
            className="company-table"
          >
            {/* <thead> */}
            <thead className="company-table-head">
              <tr>
                <th style={{ width: "25%", whiteSpace: 'wrap', textAlign: "left" }}>
                  Employee Name
                </th>
                <th style={{ width: "5%", whiteSpace: 'wrap', textAlign: "left" }}>
                 Hourly
                </th>
                <th style={{ width: "10%", textAlign:"right" }} >
                  Gross Income
                </th>
                <th style={{ width: "10%", textAlign:"right" }} >
                  Deductions
                </th>
                <th style={{ width: "10%",textAlign:"right" }} >
                  Bank to Pay
                </th>
                <th style={{ width: "10%", textAlign:"right" }} >
                  To Pay CPFB
                </th>
                <th style={{ width: "10%", textAlign:"right" }} >
                  Status
                </th>
                <th style={{ width: "10%" , textAlign:"right"}} >
                  Resigned
                </th>
                <th
                  style={{ width: "10%", whiteSpace: "nowrap", textAlign:"right"}}>
                  Payslip Status
                </th>
              </tr>
           </thead>
            <tbody>
              {filterEmployeeList
                ?.sort((a, b) => a.user.name.localeCompare(b.user.name))
                ?.map((item) => {
                  return (
                    <tr
                      key={item.id}
                      onClick={() => handleNavigate(item)}
                      style={{ cursor: "pointer" }}
                    >
                      <td
                        style={{
                          width: "25%",
                          maxWidth: '12.5rem',
                          whiteSpace: 'wrap',
                          textAlign: "left",
                          fontWeight: "bold",
                        }}
                      >
                        <Span style={{maxWidth: '12.5rem', whiteSpace: 'wrap'}} className="td-link">
                          {item?.user?.name || ""}
                        </Span>
                      </td>
                      <td
                        style={{
                          width: "5%",
                          maxWidth: '12.5rem',
                          whiteSpace: 'wrap',
                          textAlign: "left",
                          fontWeight: "bold",
                        }}
                      >
                        <Span style={{maxWidth: '12.5rem', whiteSpace: 'wrap'}} className="td-link">
                          
                          { item?.employee_payslip?.payslip_info?.income?.data[0]?.code==="basic_hourly_pay" ?"H" :""} 
                        
                     
                        </Span>
                      </td>
                      <td style={{ textAlign: "right", width: "10%" }}>
                        <Span>
                         
                          {Utils.prependDollar(
                            Utils.getNumberWithCommas(
                              Number(
                                parseFloat(
                                  Utils.getPriceFromString(
                                    item?.employee_payslip?.payslip_info?.income
                                      ?.total || "0.00"
                                  )
                                ) +
                                  parseFloat(
                                    Utils.getPriceFromString(
                                      item?.employee_payslip?.payslip_info
                                        ?.allowance?.total || "0.00"
                                    )
                                  )
                              ).toFixed(2)
                            )
                          )}
                        </Span>
                      </td>
                      <td style={{ textAlign: "right", width: "10%" }}>
                        <Span>
                          {Utils.prependDollar(
                            item?.employee_payslip?.payslip_info?.deductions
                              ?.total || "0.00"
                          )}
                        </Span>
                      </td>
                      <td style={{ textAlign: "right", width: "10%" }}>
                        <Span>
                          {Utils.prependDollar(
                            item?.employee_payslip?.payslip_info?.net_pay ||
                              "0.00"
                          )}
                        </Span>
                      </td>
                      
                      <td style={{ textAlign: "right", width: "10%" }}>
                        <Span>
                          
                          {getCPFB(item || {})}
                        </Span>
                      </td>
                      <td style={{ textAlign: "right", width: "10%" }}>
                        <Span
                          style={{
                            color:
                              textColor[item?.employment_status?.toLowerCase()],
                          }}
                        >
                          {item?.employment_status || ""}
                        </Span>
                      </td>
                      <td style={{ textAlign: "right", width: "10%" }}>
                        <Span>
                          {item?.eos !== null
                            ? Utils._dateToShow(item?.eos?.last_date_of_service)
                            : ""}
                        </Span>
                      </td>
                      <td style={{ textAlign: "right", width: "10%" }}>
                        <Span
                          style={{
                            color: item?.employee_payslip?.is_finalized
                              ? "#28a745"
                              : "#dc3545",
                          }}
                        >
                          {item?.employee_payslip?.is_finalized
                            ? "Finalised"
                            : "Unfinalised"}
                        </Span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <div style={{ textAlign: "center", padding: ".5rem" }}>
            {!filterEmployeeList?.length && <p>No records found</p>}
          </div>

          {filterEmployeeList?.length > 0 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                fontWeight: 500,
                borderRadius: "4px"
              }}
            >
              <p
                style={{
                  border: "1px solid black",
                  padding: "0.5rem 0.75rem",
                }}
              >
                {`Showing ${filterEmployeeList?.length} of ${pageInfo?.totalLength} Employees`}
              </p>
            </div>
          )}
          <CommonModal
            handleClose={() => setIsApprovalModalOpen(() => false)}
            open={isApprovalModalOpen}
            updateData={sendForApproval}
            body={`Make sure you have all the payslips finalised before sending for approval`}
            heading="Send for approval"
            conformButtonText="Confirm"
            cancelButtonText="Cancel"
          />
        </div>
      </div>
    </div>
  );
};

export default EmployeePayrollList;

const Span = styled.span`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
`;
