import React, { useEffect, useState } from "react";

import { PayrollService } from "../../../_service";
import { useAppSelector } from "../../../_app";
import { Loader, NavigateBack } from "../../../components";
import { Card, Container, Heading, Navlink } from "../Styles";
import { H3Heading } from "../../appSettings/AppSettingsStyles";
import { LoaderContainer } from "../../../CommonStyles";
import { useNavigate } from "react-router-dom";

const RegularInputItems = () => {
  const employee_id = useAppSelector(
    (state) => state.employee.employee_info.id
  );
  const company_id = useAppSelector((state) => state.user.defaultCompnayId);
  const employee = useAppSelector((state) => state?.employee);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // const [data, setData] = useState<
  //   {
  //     id: string;
  //     item_id: string;
  //     item_name: string;
  //     field_id: string;
  //     field_name: string;
  //     field_type: string;
  //     defualt_value: string;
  //     item_type_id: string;
  //     item_type_name: string;
  //     is_visible_in_payslip: boolean;
  //     payslip_name: string;
  //   }[]
  // >([]);
  const [data, setData] = useState<any>();

  const get = async () => {
    try {
      setLoading(true);
      const res = await PayrollService._getRegularInputItems(
        employee_id,
        company_id,
        employee?.last_date_of_current_month
      );
      console.log('paris in reg', res);
      setData(res.data);
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
    }
  };

  useEffect(() => {
    get();
  }, []);

  const itemTypeOrder = ["Income", "Deduction", "Allowance", "Employer contribution", "Reimbursement", "Benefit", "Other"];
  
  console.log('regular input items titan', data?.is_payitem_setting_saved);
  if (loading) {
    return (
      <div>
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      </div>
    );
  } else if (data?.is_payitem_setting_saved === false) {
    return (
      <div
        style={{
          // margin: "5rem auto",
          width: "100%",
          height: "65vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1.5rem",
            textAlign: "center",
            maxWidth: "50%",
          }}
        >
          <p
            style={{
              color: "#666",
              fontStyle: "italic",
              fontSize: "1.25rem",
            }}
          >
            No company-specific pay items have been saved yet. Please navigate
            to the company items selection page to configure your pay item
            settings.
          </p>
          <button
            onClick={() =>
              navigate("/dashboard/manage-global-report/company-specific-items")
            }
            style={{
              padding: "0.75rem 1.5rem",
              backgroundColor: "#03b381",
              color: "white",
              border: "none",
              borderRadius: "0.25rem",
              fontSize: "1rem",
              fontWeight: 500,
              cursor: "pointer",
              transition: "background-color 0.2s",
            }}
          >
            Configure Pay Items
          </button>
        </div>
      </div>
    );

  } else {
    return (
      <div style={{ position: "relative" }}>
        <div style={{ display: "flex", gap: 20 }}>
          <NavigateBack />
          <H3Heading>
            Add Regular Item - {employee.currentEmployee.name}
          </H3Heading>
        </div>
        {loading && <Loader />}
        {Array.isArray(data) ? <Container>
          {[...new Set([...itemTypeOrder, ...data?.map((p:any) => p.item_type_name)])].map((item) => {
            return (
              data
                ?.filter(
                  (a:any, i:any) => data.findIndex((s:any) => a.item_id === s.item_id) === i
                )
                ?.filter((_: any) => _.item_type_name === item).length > 0 && (
                <Card key={item}>
                  <Heading>{item?.replaceAll("_", " ")}</Heading>
                  {data
                    ?.filter(
                      (a:any, i:any) =>
                        data.findIndex((s:any) => a.item_id === s.item_id) === i
                    )
                    ?.filter((_: any) => _.item_type_name === item)
                    ?.sort((a:any, b:any)=>a.item_name?.toLowerCase()?.localeCompare(b.item_name?.toLowerCase()))
                    ?.map((item: any) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <Navlink
                            to={`/dashboard/RegularInputItemsAddField/${item.item_id}`}
                            state={data?.filter(
                              (_:any) => _.item_id === item.item_id
                            )}
                            key={item?.item_name}
                          >
                            {item?.item_name}
                          </Navlink>
                          <span>
                            {/* {item.ci_detail?.input_type ? "(Custom)" : ""} */}
                          </span>
                        </div>
                      );
                    })}
                </Card>
              )
            );
          })}
        </Container>: null}
      </div>
    );

  }

  
};

export default RegularInputItems;
