import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../_app";
import { Utils } from "../../../utils";
import { useMemo } from "react";

const activeStyle = {
  color: "#495057 !important",
  borderBottom: "3px solid #25b481",
};

const EmployeeHeader = () => {
  const permission = useAppSelector((state) => state.user.permission);

  // const hasViewAccess = useMemo(() => 
  //     Utils.checkAccess("manage_employee", "payitems", "view"), 
  //     [] 
  //   );

  return (
    <div className="employee-header">
      { Utils.checkAccess("manage_employee", "current_payroll", "view") && <NavLink
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        className="employee-sublink"
        to="/dashboard/employee-payroll"
      >
        Current Payroll
      </NavLink>}

     { Utils.checkAccess("manage_employee", "payitems", "view") && <NavLink
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        className="employee-sublink"
        to="/dashboard/pay-items"
      >
        Pay Items - Custom/Built-in
      </NavLink>}

     { Utils.checkAccess("manage_employee", "employees_list", "view") && <NavLink
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        className="employee-sublink"
        to="/dashboard/employee-list"
      >
        Employees List
      </NavLink>}

      { Utils.checkAccess("manage_employee", "add_employee", "view") && permission === "full" && (
        <NavLink
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
          className="employee-sublink"
          to="/dashboard/add-employee"
        >
          Add New Employees
        </NavLink>
      )}

      { Utils.checkAccess("manage_employee", "self_service", "view") && <NavLink
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        className="employee-sublink"
        to="/dashboard/self-service/employee-users"
      >
        Self Service
      </NavLink>}

      { Utils.checkAccess("manage_employee", "leave_overview", "view") && <NavLink
        style={({ isActive }) => (isActive ? activeStyle : undefined)}
        className="employee-sublink"
        to="/dashboard/leave-overview"
      >
        Leave Overview
      </NavLink>}
    </div>
  );
};

export default EmployeeHeader;
