import { useNavigate } from "react-router-dom";
import { Loader } from "../../components";
import { Card, Container, Heading, Navlink } from "../dashboard/Styles";
import { useEffect, useMemo, useState } from "react";
import { ReportService } from "../../_service/ReportService";
import { Notify, Utils } from "../../utils";
import { Constant } from "../../config";
import { H3Heading } from "../appSettings/AppSettingsStyles";

const Reports = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [catgeoryData, setCategoryData] = useState<any>([]);

  const navigate = useNavigate();

  // const hasAddEditAccess = useMemo(
  //   () => Utils.checkAccess("report", "default-report", "add/edit"),
  //   []
  // );

  const getCategoryList = async () => {
    setIsLoading(() => true);
    try {
      const { data, status } = await ReportService._getCategoryList();

      if (status === 200) {
        setCategoryData(() => data.data);
      }
    } catch (err) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsLoading(() => false);
  };

  useEffect(() => {
    getCategoryList();
  }, []);
  return (
    <div style={{ position: "relative" }}>
      {isLoading && <Loader />}
      <div>
        <H3Heading>Reports For This Company</H3Heading>
        <Container>
          {catgeoryData
            ?.sort((a: any, b: any) => a.id - b.id)
            ?.map((category: any) =>
              Utils.checkAccess("report", category.custom_id, "add/edit") ? (
                <Card key={category.name}>
                  <Heading>
                    {(category.name || "").replaceAll("_", " ")}
                  </Heading>
                  {category?.fields?.map((field: any) => {
                    return (
                      <Navlink
                        className="data card-row-link"
                        // to={`/dashboard/reports/${field.custom_id }`}
                        to={
                          field.custom_id === "custom_report_settings"
                            ? "/dashboard/manage-report"
                            : `/dashboard/reports/${field.custom_id}`
                        }
                        key={field?.custom_id}
                      >
                        {field?.name}
                      </Navlink>
                    );
                  })}
                </Card>
              ) : null
            )}
        </Container>
      </div>
    </div>
  );
};

export default Reports;
