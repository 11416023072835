import { useEffect, useState } from "react";

import "./style.css";
import { useNavigate } from "react-router-dom";
import { ActionColumn, ActionFlex, PulsingIcon, TableColumn } from "./styles";
import { AuthService, UserService } from "../../_service";
import DeactivateModal from "../../components/modals/DeactivateModal";
import { Notify } from "../../utils";
import SuccessButton from "../../components/buttons/SuccessButton";
import { CustomButton, Loader } from "../../components";
import { LoaderContainer } from "../../CommonStyles";
import { TableHeader } from "../../components/table";
import ManageTableHeading from "../../components/heading/ManageTableHeading";
import { FilterByChar, SearchField, TablePaginationHeader } from "../dashboard/component";
import { AiFillDelete, AiFillEdit, AiOutlineSync } from "react-icons/ai";
import { Constant } from "../../config";
import { IRASTable } from "../filing/CPFFilingStyles";
import { TableRow } from "../payslip/PayslipStyles";
import { FaTelegramPlane } from "react-icons/fa";
import { useAppSelector } from "../../_app";
import cloneDeep from "lodash.clonedeep";
import { Heading } from "../appSettings/AppSettingsStyles";
import CustomTooltip from "../../components/CustomTooltip";


interface UserData {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  is_active: boolean;
  groups: [{ name: string }];
  full_name: string;
  is_superuser: boolean;
}

interface ManageTableHeadingProps {
  title: string;
  buttonTitle?: string;
  onClick: () => void;
}

const ManageUsers = () => {
  const [allUsers, setAllUsers] = useState<UserData[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<UserData[]>([]);
  const navigate = useNavigate();
  const [isDeactivateModalActive, setIsDeactivateModalActive] =
    useState<boolean>(false);
  const [isReactivateModalActive, setIsReactivateModalActive] =
    useState<boolean>(false);
  const [isDeleteModalActive, setIsDeleteModalActive] =
    useState<boolean>(false);
  const [activeUserId, setActiveUserId] = useState<number>(0);
  const [userActiveEmail, setUserActiveEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [isResendActivationLink, setIsResendActivationLink] =
    useState<boolean>(false);
  const [char, setChar] = useState("All");
  // const { title, buttonTitle, onClick } = props;

  const user = useAppSelector((state) => state.user);


  const appUser = useAppSelector((state) => state.user);
  const [pageInfo, setPageInfo] = useState<any>({
    currentPageNumber: 1,
    dataPerPage: 10,
    totalLength: 0,
  });

  const tableHeaders = [

    {
      name: "Name",
      width: "20%",
    },
    {
      name: "E-mail",
      width: "25%",
    },
    {
      name: "Actions",
    },
    {
      name: "Roles/Permissions",
    },
  ];

  const changeUserArrays = (updatedArray: UserData[]) => {
    setAllUsers(updatedArray);

    // Reset pagination to the first page after modification
    setPageInfo((info: any) => ({
      ...info,
      currentPageNumber: 1,
      totalLength: updatedArray.length,
    }));
  };

  console.log("changeUserArrays", changeUserArrays);

  const getAllUsers = async () => {
    setIsLoading(() => true);
    try {
      const res = await UserService._getAllUsers();
      if (res.status === 200) {
        setAllUsers(() =>
          res.data.map((data: any) => ({
            ...data,
            full_name: `${data?.first_name || ""} ${data?.last_name || ""}`,
          })),
        );
        setFilteredUsers(() =>
          res.data.map((data: any) => ({
            ...data,
            full_name: `${data?.first_name || ""} ${data?.last_name || ""}`,
          })),
        );
        setPageInfo((info: any) => ({
          ...info,
          totalLength: res.data.length,
        }));
      }
    } catch (e: any) {
      Notify("Some Error occurred", 0);
    }
    setIsLoading(() => false);
  };

  const deactivateUser = async () => {
    try {
      const res = await UserService._deactivateUser({ id: activeUserId });
      if (res.status === 200) {
        Notify("User Deactivated Successfully");

        const updatedUserArray = allUsers.map((obj) => {
          if (obj.id === activeUserId) {
            return { ...obj, is_active: false };
          }
          return obj;
        });
        changeUserArrays(updatedUserArray);
      }
    } catch (e: any) {
      Notify("Some Error occurred");
    }

    setIsDeactivateModalActive(() => false);
  };

  const reactivateUser = async () => {
    try {
      const res = await UserService._reactivateUser({ id: activeUserId });
      if (res.status === 200) {
        Notify("User Activated Successfully");

        const updatedUserArray = allUsers.map((obj) => {
          if (obj.id === activeUserId) {
            return { ...obj, is_active: true };
          }
          return obj;
        });
        changeUserArrays(updatedUserArray);
      }
    } catch (e: any) {
      Notify("Some Error occurred");
    }

    setIsReactivateModalActive(() => false);
  };

  const resendActivationLink = async () => {
    try {
      const { status } = await AuthService._forgotPassword(userActiveEmail);

      if (status === 201) {
        Notify("Activation link sent successfully, please check your email", 1);
      }
    } catch (error) {
      Notify(Constant.common.something_went_wrong, 0);
    }
    setIsResendActivationLink(() => false);
  };

  const deleteUser = async () => {
    try {
      const res = await UserService._deleteUser(activeUserId);
      if (res.status >= 200 && res.status < 300) {
        Notify("User Deleted Successfully", 1);
        const deletedUserArray = allUsers.filter(
          (obj) => obj.id !== activeUserId,
        );
        changeUserArrays(deletedUserArray);
      }
    } catch (error) {
      Notify("Something wrong happened, please try again");
    }
    setIsDeleteModalActive(() => false);
  };

  const handlePageChange = (pageNo: number) => {
    setPageInfo((info: any) => ({
      ...info,
      currentPageNumber: pageNo,
    }));
  };

  useEffect(() => {
    console.log("pageInfo useEffect called", pageInfo)
    const startIndex =
      pageInfo.currentPageNumber * pageInfo.dataPerPage - pageInfo.dataPerPage;
    const endIndex =
      pageInfo.currentPageNumber * pageInfo.dataPerPage > allUsers.length
        ? allUsers.length
        : pageInfo.currentPageNumber * pageInfo.dataPerPage;

    const users = cloneDeep(allUsers);
    const applyFilter = users.filter((user: any) =>
      ["full_name", "email"].some((item: string) => user[item]?.toLowerCase()?.includes(searchText?.toLowerCase().trim()))
    )

    console.log("applyFilter in pageInfo useEffect", applyFilter)
    console.log("sliced applyFilter in pageInfo useEffect", applyFilter.slice(startIndex, endIndex), { startIndex, endIndex })

    setFilteredUsers(() => applyFilter.slice(startIndex, endIndex));
  }, [pageInfo]);

  useEffect(() => {
    console.log("char useEffect called", pageInfo, char)
    const startIndex =
      pageInfo.currentPageNumber * pageInfo.dataPerPage - pageInfo.dataPerPage;
    const endIndex =
      pageInfo.currentPageNumber * pageInfo.dataPerPage > allUsers.length
        ? allUsers.length
        : pageInfo.currentPageNumber * pageInfo.dataPerPage;
    const allUsersObj = allUsers;
    const applyFilter = allUsersObj.filter((user: any) =>
      char === "All"
        ? true
        : user.full_name?.charAt(0).toLowerCase() === char.toLowerCase()
    );

    !(char === "All") && setSearchText("")

    setFilteredUsers(() => applyFilter.slice(startIndex, endIndex));
  }, [char]);

  const changeSearchText = (value: string) => {
    setSearchText(() => value);
    // const users = allUsers;
    // const applyFilter = users.filter((user) =>
    //   user.full_name.toLowerCase().includes(value.toLowerCase().trim()),
    // );

    const users = cloneDeep(allUsers);
    const applyFilter = users.filter((user: any) =>
      ["full_name", "email"].some((item: string) => user[item]?.toLowerCase()?.includes(value?.toLowerCase().trim()))
    )

    setPageInfo((info: any) => ({
      currentPageNumber: 1,
      dataPerPage: 10,
      totalLength: applyFilter.length,
    }));

    setFilteredUsers(() => applyFilter);
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const handleDataUpdateByChar = async (props: string) => {
    // setIsFiltered(true);
    setChar(props);
    // if (props === "All") {
    //   getEmployeeList(status);
    // } else {
    //   getEmployeeListByChar(props);
    // }
  };

  return (
    <div>
      {isLoading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          <div style={{
            display: "flex",
            justifyContent: "space-between"
          }}>
            <div style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "7px"
            }}>
              <Heading>Users</Heading>
              <CustomTooltip title={<div style={{ border : "1px black solid" , padding : "5px" , borderRadius : "5px" }}>To give access to TellBoss app Admin needs to go to <span style={{ color : "darkblue" }}>secretarial.co</span> to set-up the <span style={{ color : "darkblue" }}>Subscriber Account </span></div>} placement={'right'}>
                <PulsingIcon>
                  <img src="/info-outline.svg" style={{ width: '24px', height: '24px' }} />
                </PulsingIcon>
              </CustomTooltip>
            </div>

            <div>
              {user.permission === "full" && (
                <CustomButton title="Add new user" type="secondary" onClick={() => navigate("new")} />
              )}
            </div>
          </div>

          {/* <ManageTableHeading
            title="Users"
            buttonTitle="Add new user"
            onClick={() => navigate("new")}
          /> */}


          <SearchField value={searchText} onChangeText={changeSearchText} />

          <FilterByChar value={char} onChange={handleDataUpdateByChar} />

          <TablePaginationHeader
            pageInfo={pageInfo}
            name="Users"
            handlePageValueChange={handlePageChange}
          />
          <div>
            <IRASTable style={{ width: "100%" }}>
              <TableHeader headerColumns={tableHeaders} variant="filled" />
              {filteredUsers?.length > 0 ? (
                filteredUsers.sort((a, b) => a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase()))                
                .map((user) => (
                  <TableRow key={user?.email}>

                    <TableColumn>
                      <p style={{ overflow: "hidden" }}>
                        {user?.full_name || ""}
                      </p>
                    </TableColumn>
                    <TableColumn>
                      <p style={{ overflow: "hidden" }}>{user?.email || ""}</p>
                    </TableColumn>
                    {!user?.is_superuser ? <ActionColumn>
                      <ActionFlex>
                        {user.is_active && appUser.permission === "full" ? (
                          <CustomButton
                            onClick={() => {
                              setIsDeactivateModalActive(() => true);
                              setActiveUserId(() => user.id);
                            }}
                            title=""
                            width="30px"
                            size="small"
                            icon={<AiFillDelete />}
                            popoverText="De-Activate User"
                          />
                        ) : (
                          <SuccessButton
                            onClick={() => {
                              setIsReactivateModalActive(() => true);
                              setActiveUserId(() => user.id);
                            }}
                            title="Re-activate"
                          />
                        )}

                        {/* <CustomButton
                          onClick={() => {
                            setIsDeleteModalActive(() => true);
                            setActiveUserId(() => user.id);
                          }}
                          title="Delete"
                          type="secondary"
                          del={true}
                          width="70px"
                          size="small"
                        /> */}
                        {user.is_active && appUser.permission === "full" && (
                          <CustomButton
                            onClick={() =>
                              navigate("edit", {
                                state: {
                                  userId: user.id,
                                },
                              })
                            }
                            title=""
                            popoverText="Change Permissions or Restrict Company"
                            width="30px"
                            size="small"
                            type="secondary"
                            icon={<AiFillEdit />}
                          />
                        )}
                        {user.is_active && (
                          <CustomButton
                            size="small"
                            width="30px"
                            title=""
                            onClick={() => {
                              setUserActiveEmail(() => user.email);
                              setIsResendActivationLink(true);
                            }}
                            icon={<FaTelegramPlane />}
                            popoverText="Resend Activation Link"
                          />
                        )}
                      </ActionFlex>
                    </ActionColumn> : <div style={{ width: "150px" }}></div>}
                    <TableColumn>
                      {user.is_superuser ? <span style={{ fontWeight: "600" }}>SUPERUSER</span> : user.groups.map((group, index) => (
                        <span>
                          {group.name}
                          {index + 1 !== user.groups.length ? ", " : ""}
                        </span>
                      ))}
                    </TableColumn>
                  </TableRow>
                ))
              ) : (
                <p
                style={{
                  whiteSpace: "nowrap",
                  marginLeft: "140%", 
                  paddingTop: "10px", 
                }}
              >
                No record for this action found.
              </p>
              
              )}
            </IRASTable>
            <DeactivateModal
              open={isDeactivateModalActive}
              closeModal={() => setIsDeactivateModalActive(() => false)}
              approveAction={deactivateUser}
            />

            {/* <DeactivateModal
              open={isDeleteModalActive}s
              closeModal={() => setIsDeleteModalActive(() => false)}
              approveAction={deleteUser}
              variant="delete"
            /> */}
            <DeactivateModal
              open={isReactivateModalActive}
              closeModal={() => setIsReactivateModalActive(() => false)}
              approveAction={reactivateUser}
              variant="reactivate"
            />
            <DeactivateModal
              open={isResendActivationLink}
              closeModal={() => setIsResendActivationLink(() => false)}
              approveAction={resendActivationLink}
              variant="resendActivationLink"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ManageUsers;
