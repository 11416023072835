import { PayrollService } from "../../../_service";
import { useAppSelector } from "../../../_app";
import { useEffect, useState } from "react";
import { Card, Container, Heading, Navlink } from "../Styles";
import { Loader, NavigateBack } from "../../../components";
import { H3Heading } from "../../appSettings/AppSettingsStyles";
import { LoaderContainer } from "../../../CommonStyles";
import { useNavigate } from "react-router-dom";

const PayslipCalculation = () => {
  const employee = useAppSelector((state) => state?.employee);
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const get = async () => {
    try {
      setLoading(true);
      const da = await PayrollService._getPayslipCalculationPageData(
        employee?.employee_info?.id,
        employee?.last_date_of_current_month
      );
      setData(da);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    get();
  }, []);

  const keyOrder = [
    "income",
    "deduction",
    "allowance",
    "reimbursement",
    "other",
  ];

  console.log('payslip calculation data', data, data?.data?.is_payitem_setting_saved  );

  console.log('berlin payslip', data?.data)

  if (loading) {
    return (
      <div>
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      </div>
    );
  } else if (data?.data?.is_payitem_setting_saved === false) {
    return (
      <div
        style={{
          // margin: "5rem auto",
          width: "100%",
          height: "65vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1.5rem",
            textAlign: "center",
            maxWidth: "50%",
          }}
        >
          <p
            style={{
              color: "#666",
              fontStyle: "italic",
              fontSize: "1.25rem",
            }}
          >
            No company-specific pay items have been saved yet. Please navigate
            to the company items selection page to configure your pay item
            settings.
          </p>
          <button
            onClick={() =>
              navigate("/dashboard/manage-global-report/company-specific-items")
            }
            style={{
              padding: "0.75rem 1.5rem",
              backgroundColor: "#03b381",
              color: "white",
              border: "none",
              borderRadius: "0.25rem",
              fontSize: "1rem",
              fontWeight: 500,
              cursor: "pointer",
              transition: "background-color 0.2s",
            }}
          >
            Configure Pay Items
          </button>
        </div>
      </div>
    );

  } else {
    return (
      <div style={{ position: "relative" }}>
        <div style={{ display: "flex", gap: 20 }}>
          <NavigateBack />
          <H3Heading>
            Add Payslip Item - {employee.currentEmployee.name}
          </H3Heading>
        </div>{" "}
        {loading && <Loader />}
        <Container>
          {keyOrder.map((type: any) => {
            return (
              data?.data?.calculation_list?.filter(
                (_: any) => _.calculation_type === type
              )?.length > 0 && (
                <Card key={type}>
                  <Heading>{type?.replaceAll("_", " ") || ""}</Heading>
                  {data?.data?.calculation_list
                    ?.filter((_: any) => _.calculation_type === type)
                    ?.sort((a:any, b:any)=>a.calculation_name?.toLowerCase()?.localeCompare(b.calculation_name?.toLowerCase()))
                    ?.map((item: any) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <Navlink
                            to={`/dashboard/EmployeePayslipAddField/${item.calculation_code}/${item?.pc_id}`}
                            key={item.calculation_code}
                            state={item}
                          >
                            {item?.calculation_name}
                          </Navlink>
                          {/* <span>{item.ci ? "(Custom)" : ""}</span> */}
                        </div>
                      );
                    })}
                </Card>
              )
            );
          })}
        </Container>
      </div>
    );

  }
};

export default PayslipCalculation;
